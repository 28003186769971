.overlay {
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #007BFF, #00BFFF);
}

@keyframes animateC {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.app-spinner-container {
    /* position: absolute; 
    z-index: 10;
    top: 15%;
    margin: 4rem auto; */
    margin: 30rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 450ms ease;
    -webkit-transition: all 450ms ease;
}

.app-spinner {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    background: transparent;
    border: 3px solid #EBEDF4;
    border-radius: 50%;
    text-align: center;
    line-height: 120px;
    font-family: sans-serif;
    font-size: 20px;
    letter-spacing: 4px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-spinner:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #1141D2;
    border-right: 3px solid #1141D2;
    border-radius: 50%;
    animation: animateC 1s linear infinite;
    -webkit-animation: animateC 1s linear infinite;
    ;
}